import { TextField, MenuItem } from '@mui/material';
import { SORT_BY_DATE_LABEL } from 'locales/shiftClaimList/es';

export enum SortingOptionsEnum {
  SHIFT_TIME = 'SHIFT_TIME',
  SHIFT_PUBLICATION_TIME = 'SHIFT_PUBLICATION_TIME',
}

const SortingOptionsMap = {
  [SortingOptionsEnum.SHIFT_TIME]: 'Turno',
  [SortingOptionsEnum.SHIFT_PUBLICATION_TIME]: 'Publicación',
}

interface SortingSelectorProps {
  selectedOption: SortingOptionsEnum;
  options: SortingOptionsEnum[];
  onChange: (value: string) => void;
  className?: string;
}

const SortingSelector: React.FC<SortingSelectorProps> = ({
  selectedOption,
  options,
  onChange,
  className,
}) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }} className={className}>
      <span>{SORT_BY_DATE_LABEL}</span>
      <TextField
        select
        value={selectedOption}
        onChange={(e) => onChange(e.target.value)}
        size="small"
        sx={{
          backgroundColor: 'white',
          minWidth: 120
        }}
      >
        {options.map((option) => (
          <MenuItem value={option}>{SortingOptionsMap[option]}</MenuItem>
        ))}
      </TextField>
    </div>
  );
};

export default SortingSelector;
