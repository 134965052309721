import React, { useState } from "react";
import LivoIcon from "assets/LivoLogo";
import { CircularProgress } from "@mui/material";

interface RejectButtonProps {
    onClick: () => void;
    isDisabled: boolean;
    isLoading?: boolean;
}

export const RejectButton: React.FC<RejectButtonProps> = ({
    onClick,
    isDisabled,
    isLoading = false,
}) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <button
            onClick={onClick}
            disabled={isDisabled || isLoading}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className={`
                flex items-center justify-center p-small
                rounded-[80px] bg-white hover:bg-Negative-500 ring-[1px] ring-solid ring-Negative-200 hover:ring-Negative-500`}
        >
            {isLoading ?
            <CircularProgress 
                size={16} 
                sx={{ 
                    color: isHovered ? "white" : "#FA3D3B"
                }} 
            /> :
                <LivoIcon name="close" size={16} color={isHovered ? "white" :  "#FA3D3B" } />
            }
        </button>
    );
}
