import React, { useState } from "react";
import LivoIcon from "assets/LivoLogo";
import { CircularProgress } from "@mui/material";

interface AcceptButtonProps {
    onClick: () => void;
    isDisabled: boolean;
    isLoading?: boolean;
}

export const AcceptButton: React.FC<AcceptButtonProps> = ({
    onClick,
    isDisabled,
    isLoading = false,
}) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <button
            onClick={onClick}
            disabled={isDisabled || isLoading}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className={`
                flex items-center justify-center p-small
                rounded-[80px] bg-white hover:bg-Primary-500 ring-[1px] ring-solid ring-Primary-500`}
        >
            {isLoading ?
                <CircularProgress 
                    size={16} 
                    sx={{ 
                        color: isHovered ? "white" : "#149EF2"
                    }} 
                /> :
                <LivoIcon name="check" size={16} color={isHovered ? "white" :  "#149EF2"} />
            }
        </button>
    );
}
