import { CircularProgress } from "@mui/material"
import { ReactNode, useEffect, useState } from "react"
import { cancelShiftRequest, fetchShiftDetails, updateCapacityRequest } from "services/shifts-calendar"
import { Shift, ShiftTimeInDayEnum, ShiftTimeStatusEnum } from "types/shifts"
import { ShiftDetailsHeader } from "./ShiftDetailsHeader"
import { ShiftDetailsBody } from "./ShiftDetailsBody"
import { ClaimRequest, ClaimStatusEnunm, DenarioErrorPayload } from "types/claims"
import { AcceptedClaimsComponent } from "./AcceptedClaimsComponent"
import { CancelShiftReasonsModal } from "./CancelShiftReasonsModal"
import { DECREASE_CAPACITY_MODAL_TITLE, REJECT_PROFESSIONAL_MODAL_TITLE } from "locales/shiftClaimDetails/es"
import { EditShiftModal } from "./EditShiftModal"
import { PendingClaimsComponent } from "./PendingClaimsComponent"
import { ProfessionalClaimDetails } from "./professionalClaim/ProfessionalClaimDetails"
import { ShiftModalityEnum } from "types"
import { InternalProfessionalClaimDetails } from "./professionalClaim/InternalProfessionalClaimDetails"
import { shiftCancellationAccept, shiftCancellationResolve, shiftClaimAccept, shiftClaimReject } from "services/claims"
import { RejectProfessionalReasonsModal } from "./RejectProfessionalReasonsModal"
import { PublishShiftModal } from "components/publishShift/PublishShiftModal"
import { ShiftConfiguration } from "components/publishShift/BaseShiftForm"
import { CancelShiftModal } from "./CancelShiftModal"
import { fetchActivity } from "store/actions/activityShiftListActions"
import { useDispatch } from "react-redux"
import { AppDispatch } from "store"
import { ExpandableContainer } from "components/common/ExpandableContainer"
import moment from "moment"
import { AcceptProfessionalWithClaimReason } from "./professionalClaim/AcceptProfessionalWithClaimReason"
import { handleApiError, isDenarioNotSyncedError } from "services/api"
import { ConfirmationModal } from "components/common/ConfirmationModal"


interface ShiftDetailsSectionProps {
    reloadShifts: () => void,
    selectedShiftId: number | null,
    setSelectedShiftId: (id: number | null) => void,
}

export const ShiftDetailsSection: React.FC<ShiftDetailsSectionProps> = ({
    reloadShifts,
    selectedShiftId,
    setSelectedShiftId
}) => {
    const [shiftDetails, setShiftDetails] = useState<Shift | null>(null)
    const [loading, setLoading] = useState(false)
    const [cancelShiftModalOpen, setCancelShiftModalOpen] = useState(false)
    const [removeCapacityModalOpen, setRemoveCapacityModalOpen] = useState(false)
    const [editShiftDetailsModalOpen, setEditShiftDetailsModalOpen] = useState(false)
    const [publishShiftModalOpen, setPublishShiftModalOpen] = useState(false)
    const [selectedClaimId, setSelectedClaimId] = useState<number | null>(null)
    const [acceptingClaimId, setAcceptingClaimId] = useState<number | undefined>(undefined);
    const [rejectingClaimId, setRejectingClaimId] = useState<number | null>(null)
    const [selectedSlotReasonClaimId, setSelectedSlotReasonClaimId] = useState<number | null>(null)
    const dispatch = useDispatch<AppDispatch>()
    const selectedClaim = shiftDetails?.claims.find((claim) => claim.id === selectedClaimId)
    const selectedSlotClaim = shiftDetails?.claims.find((claim) => claim.id === selectedSlotReasonClaimId)
    const [selectedOption, setSelectedOption] = useState('professionals')

    const [denarioError, setDenarioError] = useState<DenarioErrorPayload | null>(null);
    const [currentAction, setCurrentAction] = useState<{
        shiftId: number;
        claimId: number;
        actionType: 'accept' | 'reject' | 'cancelAccept';
        reason?: string;
        details?: string;
        skipConstraints?: boolean;
    } | null>(null);
    const [retryingActionWithDenario, setRetryingActionWithDenario] = useState(false);
    const [actionWithoutDenario, setActionWithoutDenario] = useState(false);

    const loadData = async () => {
        if (!selectedShiftId) {
            return
        }
        setLoading(true)
        await fetchShiftDetails(selectedShiftId)
            .then((data) => {
                setLoading(false)
                if (data) {
                    setShiftDetails(data)
                }
            })
            .catch((error) => {
                setLoading(false)
                console.error(error)
            })
    }

    useEffect(() => {
        loadData()
        setSelectedClaimId(null)
        setSelectedOption('professionals')
    }, [selectedShiftId, reloadShifts])

    const pendingClaims = shiftDetails?.claims.filter((claim) => claim.status === ClaimStatusEnunm.PENDING_APPROVAL);
    const isShiftFullyBooked = shiftDetails?.totalAcceptedClaims === shiftDetails?.capacity;
    const flex = ((pendingClaims && pendingClaims.length > 0) || selectedClaim || !isShiftFullyBooked) ? "66.6%" : "33.3%"

    async function acceptClaim(shiftId: number, claimId: number, skipConstraints?: boolean) {
        setAcceptingClaimId(claimId);
        shiftClaimAccept(shiftId, claimId, undefined, undefined, skipConstraints).then(() => {
            dispatch(fetchActivity());
            loadData();
            reloadShifts();
            setRetryingActionWithDenario(false);
            setActionWithoutDenario(false);
            setDenarioError(null);
        }).catch((error) => {
            setRetryingActionWithDenario(false);
            setActionWithoutDenario(false);
            if (isDenarioNotSyncedError(error.response.data)) {
                const extraData = error.response.data.extraData as DenarioErrorPayload;
                setDenarioError(extraData);
                setCurrentAction({ shiftId, claimId, actionType: 'accept' });
            } else {
                handleApiError(error);
            }
        }).finally(() => {
            setAcceptingClaimId(undefined);
        });
    }

    async function rejectClaim(shiftId: number, claimId: number, reason: string, details: string, skipConstraints?: boolean) {
        shiftClaimReject(shiftId, claimId, reason, details, skipConstraints).then(() => {
            setRejectingClaimId(null)
            setSelectedClaimId(null)
            loadData()
            reloadShifts()
            setRetryingActionWithDenario(false);
            setActionWithoutDenario(false);
            setDenarioError(null);
        }).catch((error) => {
            setRetryingActionWithDenario(false);
            setActionWithoutDenario(false);
            if (isDenarioNotSyncedError(error.response.data)) {
                const extraData = error.response.data.extraData as DenarioErrorPayload;
                setDenarioError(extraData);
                setCurrentAction({ shiftId, claimId, actionType: 'reject', reason, details });
            } else {
                handleApiError(error);
            }
        });
    }

    async function acceptCancellationRequest(shiftId: number, claimId: number, skipConstraints?: boolean) {
        shiftCancellationAccept(shiftId, claimId, skipConstraints).then(() => {
            dispatch(fetchActivity())
            loadData()
            reloadShifts()
            setSelectedClaimId(null)
            setRetryingActionWithDenario(false);
            setActionWithoutDenario(false);
            setDenarioError(null);
        }).catch((error) => {
            setRetryingActionWithDenario(false);
            setActionWithoutDenario(false);
            if (isDenarioNotSyncedError(error.response.data)) {
                const extraData = error.response.data.extraData as DenarioErrorPayload;
                setDenarioError(extraData);
                setCurrentAction({ shiftId, claimId, actionType: 'cancelAccept' });
            } else {
                handleApiError(error);
            }
        });
    }

    return (
        <ExpandableContainer
            width={selectedShiftId ? flex : '0%'}
        >
            <div
                className={`flex bg-white h-full border-l-[1px] border-solid border-Divider-Subtle w-full min-w-fit`}
            >
                {loading && selectedShiftId ?

                    <div
                        className="flex flex-col items-center justify-center h-full w-full"
                    >
                        <CircularProgress />
                    </div>
                    :
                    shiftDetails ?
                        <div
                            className={`flex flex-1 h-full divide-x-[1px] divide-Divider-Default`}
                        >
                            <div
                                className="flex flex-1 flex-col h-full pt-[24px]"
                            >
                                <ShiftDetailsHeader
                                    title={shiftDetails.specialization.translations.es}
                                    onCopy={() => {
                                        setPublishShiftModalOpen(true)
                                    }}
                                    onClose={() => {
                                        setSelectedShiftId(null)
                                    }}
                                    onEdit={() => {
                                        setEditShiftDetailsModalOpen(true)
                                    }}
                                    onDelete={() => {
                                        setCancelShiftModalOpen(true)
                                    }}
                                    editable={shiftDetails.shiftTimeStatus === ShiftTimeStatusEnum.UPCOMING}
                                    recurrent={shiftDetails.recurrentDates?.length > 1}
                                />
                                <div
                                    className="flex flex-col flex-1 h-full space-y-[16px] divide-y-[1px] divide-Divider-Default overflow-y-auto no-scrollbar"
                                >
                                    <ShiftDetailsBody
                                        shift={shiftDetails}
                                    />

                                    <AcceptedClaimsComponent
                                        selectedOption={selectedOption}
                                        setSelectedOption={setSelectedOption}
                                        shouldShowSlotReasonList={shiftDetails.shouldShowSlotReasonList}
                                        claims={shiftDetails.claims.filter((claim) => claim.status === ClaimStatusEnunm.APPROVED)}
                                        capacity={shiftDetails.capacity}
                                        updateCapacity={(newCapacity: number) => {
                                            updateCapacityRequest(
                                                shiftDetails!!.id,
                                                newCapacity).then(() => {
                                                    loadData()
                                                    reloadShifts()
                                                })
                                        }}
                                        editable={shiftDetails.shiftTimeStatus === ShiftTimeStatusEnum.UPCOMING}
                                        onDecreaseCapacity={() => {
                                            setRemoveCapacityModalOpen(true)
                                        }}
                                        selectClaim={(claim) => setSelectedClaimId(claim.id)}
                                    />
                                </div>
                            </div>

                            {selectedSlotClaim ?
                                <div
                                    className="flex flex-1  pt-[24px] overflow-y-scroll w-full h-full no-scrollbar"
                                >

                                    <AcceptProfessionalWithClaimReason
                                        claim={selectedSlotClaim}
                                        goBack={() => {
                                            setSelectedSlotReasonClaimId(null)
                                        }}
                                        onAccept={() => {
                                            loadData()
                                            reloadShifts()
                                            setSelectedSlotReasonClaimId(null)
                                        }}
                                        shiftId={shiftDetails.id}
                                    />
                                </div> :

                                selectedClaim ?

                                    <div
                                        className="flex flex-1  pt-[24px]"
                                    >
                                        {


                                            selectedClaim.modality === ShiftModalityEnum.INTERNAL ?
                                                <InternalProfessionalClaimDetails
                                                    onUpdateSlotReason={() => {
                                                        loadData()
                                                        reloadShifts()
                                                    }}
                                                    shiftId={shiftDetails.id}
                                                    claim={selectedClaim}
                                                    shiftStatus={shiftDetails.shiftTimeStatus}
                                                    goBack={() => {
                                                        setSelectedClaimId(null)
                                                    }}
                                                    onAccept={() => {
                                                        if (selectedClaim.slotReasonOptions && selectedClaim.slotReasonOptions?.length > 0) {
                                                            setSelectedSlotReasonClaimId(selectedClaim.id)
                                                        } else {
                                                            acceptClaim(shiftDetails.id, selectedClaimId!!).then(() => {
                                                                setSelectedClaimId(null)
                                                            })
                                                        }
                                                    }}
                                                    onReject={() => {
                                                        setRejectingClaimId(selectedClaimId)
                                                    }}
                                                    acceptCancellationRequest={(claimId) => {
                                                        acceptCancellationRequest(shiftDetails.id, claimId);
                                                    }}
                                                    rejectCancellationRequest={(claimId) => {
                                                        shiftCancellationResolve(shiftDetails.id, claimId).then(() => {
                                                            dispatch(fetchActivity())

                                                            loadData()
                                                            reloadShifts()
                                                        })
                                                    }}
                                                    onDelete = {reloadShifts}

                                                />

                                                : <ProfessionalClaimDetails
                                                    shiftId={shiftDetails.id}
                                                    claimId={selectedClaim.id}
                                                    goBack={() => {
                                                        setSelectedClaimId(null)
                                                    }}
                                                    onAccept={() => {
                                                        if (selectedClaim.slotReasonOptions && selectedClaim.slotReasonOptions.length > 0) {
                                                            setSelectedSlotReasonClaimId(selectedClaim.id)
                                                        } else {
                                                            acceptClaim(shiftDetails.id, selectedClaimId!!).then(() => {
                                                                setSelectedClaimId(null)
                                                            })
                                                        }
                                                    }}
                                                    onReject={() => {
                                                        setRejectingClaimId(selectedClaimId)
                                                    }}
                                                    onUpdateSlotReason={() => {
                                                        loadData()
                                                        reloadShifts()
                                                    }}
                                                />}
                                    </div> :

                                    !isShiftFullyBooked &&
                                    <div
                                        className="flex flex-1  pt-[24px] px-small overflow-y-auto overflow-x-hidden"
                                    >
                                        <PendingClaimsComponent
                                            claims={pendingClaims}
                                            onAccept={(claim: ClaimRequest) => {
                                                if (claim.slotReasonOptions && claim.slotReasonOptions?.length > 0) {
                                                    setSelectedSlotReasonClaimId(claim.id)
                                                } else {
                                                    acceptClaim(shiftDetails.id, claim.id);
                                                }
                                            }}
                                            onReject={(claimId: number) => {
                                                dispatch(fetchActivity())
                                                setRejectingClaimId(claimId)
                                            }}
                                            selectClaim={(claim) => setSelectedClaimId(claim.id)}
                                            acceptingClaimId={acceptingClaimId}
                                            rejectingClaimId={rejectingClaimId || undefined}
                                        />
                                    </div>
                            }
                        </div >

                        : null
                }
                {shiftDetails && <CancelShiftModal
                    isOpen={cancelShiftModalOpen}
                    onClose={() => setCancelShiftModalOpen(false)}
                    cancelShift={(reason, details, bulkOperation) => {
                        cancelShiftRequest(shiftDetails!!.id, reason, details, bulkOperation).then(() => {
                            setSelectedShiftId(null)
                            setCancelShiftModalOpen(false)
                            reloadShifts()
                        })
                    }}
                    isRecurrent={!!(shiftDetails?.recurrentDates && shiftDetails?.recurrentDates?.length > 1)}
                    shiftDetails={shiftDetails}
                />}
                <CancelShiftReasonsModal
                    title={DECREASE_CAPACITY_MODAL_TITLE}
                    isOpen={removeCapacityModalOpen}
                    goBack={() => setRemoveCapacityModalOpen(false)}
                    cancelShift={(reason, details) => {
                        updateCapacityRequest(shiftDetails!!.id, shiftDetails!!.capacity - 1, reason, details).then(() => {
                            setRemoveCapacityModalOpen(false)
                            loadData()
                            reloadShifts()
                        })

                    }}
                />

                <EditShiftModal
                    isOpen={editShiftDetailsModalOpen && shiftDetails !== null}
                    onClose={() => {
                        setEditShiftDetailsModalOpen(false)
                    }}
                    reloadData={() => {
                        loadData()
                        reloadShifts()
                    }}
                    shiftDetails={shiftDetails}
                />
                <RejectProfessionalReasonsModal
                    title={REJECT_PROFESSIONAL_MODAL_TITLE}
                    isOpen={rejectingClaimId !== null}
                    goBack={() => setRejectingClaimId(null)}
                    rejectProfessional={(reason, details) => {
                        rejectClaim(shiftDetails!!.id, rejectingClaimId!!, reason, details);
                    }}
                />
                <PublishShiftModal
                    isOpen={publishShiftModalOpen && shiftDetails !== null}
                    onClose={() => {
                        setPublishShiftModalOpen(false)
                    }}
                    selectedDate={shiftDetails && shiftDetails.startTime || new Date().toISOString()}
                    shiftTimeInDay={ShiftTimeInDayEnum.MORNING}
                    reloadData={() => {
                        reloadShifts()
                    }}
                    shiftDetails={{
                        ...shiftDetails,
                        recurrentDates: shiftDetails ? [moment(shiftDetails.startTime).format('YYYY-MM-DD')] : [],
                        compensationOptions: shiftDetails?.compensationOptions.map((option) => option.value) || []
                    } as ShiftConfiguration}
                />
            </div >

            {denarioError && (
                <ConfirmationModal
                    title={denarioError.title}
                    subtitle={denarioError.description}
                    isOpen={true}
                    handleClose={() => {
                        setDenarioError(null);
                        setRetryingActionWithDenario(false);
                        setActionWithoutDenario(false);
                    }}
                    dismissTitle={denarioError.actions.secondary.title}
                    buttonTitle={denarioError.actions.primary.title}
                    onPress={() => {
                        setRetryingActionWithDenario(true);
                        if (currentAction) {
                            switch (currentAction.actionType) {
                                case 'accept':
                                    acceptClaim(currentAction.shiftId, currentAction.claimId);
                                    break;
                                case 'reject':
                                    rejectClaim(currentAction.shiftId, currentAction.claimId, currentAction.reason!, currentAction.details!);
                                    break;
                                case 'cancelAccept':
                                    acceptCancellationRequest(currentAction.shiftId, currentAction.claimId);
                                    break;
                            }
                        }
                    }}
                    onDismiss={() => {
                        setActionWithoutDenario(true);
                        if (currentAction) {
                            switch (currentAction.actionType) {
                                case 'accept':
                                    acceptClaim(currentAction.shiftId, currentAction.claimId, true);
                                    break;
                                case 'reject':
                                    rejectClaim(currentAction.shiftId, currentAction.claimId, currentAction.reason!, currentAction.details!, true);
                                    break;
                                case 'cancelAccept':
                                    acceptCancellationRequest(currentAction.shiftId, currentAction.claimId, true);
                                    break;
                            }
                        }
                    }}
                    buttonIsLoading={retryingActionWithDenario}
                    dismissIsLoading={actionWithoutDenario}
                />
            )}
        </ExpandableContainer>
    )
}
