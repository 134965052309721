import { Category, Unit } from "types/shifts";
import { api, handleApiError } from "./api";

export type AccountInfo = {
  id: string,
  email: string,
  firstName: string,
  lastName: string,
  facility: {
    id: number,
    name: string,
    cityCode: string,
    address: string,
    addressCity: string,
    addressCountry: string,
    addressLatitude: number,
    addressLongitude: number,
    mapLink: string,
    categories: Category[],
    skillsByCategory: { [key: string]: SkillDefinition[] }
    livoInternalOnboardingStrategy?: string,
    portalShiftsOrderingByEnabled?: boolean,
  },
  livoPoolOnboarded: boolean,
  livoInternalOnboarded: boolean,
  visibleTabMenu: VisibleTabEnum[],
  lastTimeSignIn: string,
  units: Unit[],
  userFeatures?: UserFeatureEnum[],
}

export type SkillDefinition = {
  value: string,
  displayText: string
}

export const fetchAccountInfo = () => {
  const url = '/facility/portal/account/profile';
  return api
    .get(url)
    .then(response => {
      return response.data;
    })
    .catch(handleApiError);
}

export interface ChangePasswordData {
  oldPassword: string;
  newPassword: string;
}


export const changePasswordRequest = async (
  changePasswordData: ChangePasswordData,
): Promise<boolean | void> => {
  const url = '/facility/account/change-password';

  return api
    .post(url, changePasswordData)
    .then(response => response.status === 200)
    .catch(handleApiError);
};

export const resetPasswordRequest = (email: string) => {
  const url = '/facility/account/forgotten-password/request-reset-password';
  const body = { email: email };

  return api
    .post(url, body)
    .then(response => response.status === 200)
    .catch(handleApiError);
};
export const verifyMFARequest = (email: string, code: string): Promise<string | void> => {
  const url = '/facility/account/verify-login-mfa';
  const body = { email: email, mfaCode: code };

  return api
    .post(url, body)
    .then(response => `${response.data.tokenType} ${response.data.accessToken}`)
    .catch(handleApiError);
};

export enum VisibleTabEnum {
  CALENDAR = "CALENDAR",
  ACTIONABLE_SHIFTS = "ACTIONABLE_SHIFTS",
  NEXT_SHIFTS_LIST = "NEXT_SHIFTS_LIST",
  OFFER_MANAGEMENT = "OFFER_MANAGEMENT",
  LEGAL_DOCUMENTATION = "LEGAL_DOCUMENTATION",
  INTERNAL_STAFF_MANAGEMENT = "INTERNAL_STAFF_MANAGEMENT",
  FACILITY_STAFF_MANAGEMENT = "FACILITY_STAFF_MANAGEMENT"
}

export enum UserFeatureEnum {
  FAVOURITE_PROFESSIONALS_MANAGEMENT = "FAV_PRO_MGMT"
}
