import { CATEGORY_LABEL, DENARIO_PRO_MODAL_APPLIED_DATA_LABEL, DENARIO_PRO_MODAL_RETURN_LABEL, DENARIO_PRO_MODAL_SUBTITLE, DENARIO_PRO_MODAL_SUBTITLE_2, DENARIO_PRO_MODAL_TITLE, DNI_LABEL, EMPLOYEE_NUMBER_LABEL, UNIT_LABEL } from "locales/internalProfessionalPage"
import { DenarioProfessional } from "types/internal"

interface DenarioProfessionalModalProps {
    denarioProfessional: DenarioProfessional,
    onClose: () => void,
    onAcceptProfessionalData: () => void
}

export const DenarioProfessionalModal: React.FC<DenarioProfessionalModalProps> = ({ 
    denarioProfessional,
    onClose,
    onAcceptProfessionalData
}) => {
    return (
        <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-6 w-96 relative z-[51]">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-bold">{DENARIO_PRO_MODAL_TITLE}</h2>
                </div>
                <div className="mb-4">
                    <p className="mb-2 text-[#2c3038] text-base font-normal font-['Roboto'] leading-normal">{DENARIO_PRO_MODAL_SUBTITLE}</p>
                    <div className="bg-gray-50 p-4 rounded-md">
                        <p className="font-medium">{denarioProfessional.firstName} {denarioProfessional.lastName}</p>
                        <p className="text-gray-600">{DNI_LABEL}: {denarioProfessional.nationalId}</p>
                        <p className="text-gray-600">{CATEGORY_LABEL}: {denarioProfessional.category}</p>
                        <p className="text-gray-600">{EMPLOYEE_NUMBER_LABEL}: {denarioProfessional.employeeNumber}</p>
                        <p className="text-gray-600">{UNIT_LABEL}: {denarioProfessional.unit}</p>
                    </div>
                    
                    <p className="mt-4 text-[#2c3038] text-base font-normal font-['Roboto'] leading-normal">{DENARIO_PRO_MODAL_SUBTITLE_2}</p>
                </div>
                <div className="flex justify-between w-full">
                    <button
                        onClick={onClose}
                        className="w-[49%] px-4 py-2 rounded-full hover:bg-gray-50"
                    >
                        {DENARIO_PRO_MODAL_RETURN_LABEL}
                    </button>
                    <button
                        className="w-[49%] px-4 py-2 bg-blue-600 text-white rounded-full hover:bg-blue-700"
                        onClick={onAcceptProfessionalData}
                    >
                        {DENARIO_PRO_MODAL_APPLIED_DATA_LABEL}
                    </button>
                </div>
            </div>
        </div>
    )
}