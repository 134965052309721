import { ClaimRequest } from "types/claims"
import { ClaimRow } from "./ClaimRow"
import { PENDING_CLAIMS_TITLE } from "locales/shiftClaimDetails/es";
import { ShiftCardTag } from "components/shifts/ShiftCardTag";
import { AcceptButton } from "./AcceptButton";
import { RejectButton } from "./RejectButton";
import { SEARCHING_PROFESSIONALS_DESCRIPTION } from "locales/professionals/favorite";
import OpenFavoriteProfessionals from "./professionalClaim/OpenFavoriteProfessionals";

interface PendingClaimsComponentProps {
    claims?: ClaimRequest[];
    acceptingClaimId?: number;
    rejectingClaimId?: number;
    onAccept: (claimId: ClaimRequest) => void;
    onReject: (claimId: number) => void;
    selectClaim: (claim: ClaimRequest) => void
}

export const PendingClaimsComponent: React.FC<PendingClaimsComponentProps> = ({
    claims,
    acceptingClaimId,
    rejectingClaimId,
    onAccept,
    onReject,
    selectClaim,
}) => {
    return (
        <div
            className="flex flex-col w-full min-w-fit"
        >
            <div
                className="flex items-center space-x-tiny mb-small"
            >
                <p
                    className="heading-md"
                >
                    {PENDING_CLAIMS_TITLE}
                </p>
                {claims && claims.length > 0 &&
                    <ShiftCardTag
                        isFilled={false}
                        totalPendingClaims={claims.length}
                    />
                }
            </div>

            {claims && claims.length > 0 ?
            <div
                className="flex flex-col w-full flex-1 space-y-small justify-start"
            >
                {
                    claims.map((claim, index) => (
                        <div
                            key={index}
                            className="flex w-full items-center justify-between space-x-small"
                        >
                            <ClaimRow
                                claim={claim}
                                onClick={() => selectClaim(claim)}
                            />
                            <div
                                className="flex flex-row items-center justify-center space-x-small"
                            >
                                <AcceptButton
                                    onClick={() => {
                                        onAccept(claim)
                                    }}
                                    isDisabled={false}
                                    isLoading={acceptingClaimId === claim.id}
                                />
                                <RejectButton
                                    onClick={() => {
                                        onReject(claim.id)
                                    }}
                                    isDisabled={false}
                                    isLoading={rejectingClaimId === claim.id}
                                />
                            </div>
                        </div>
                    ))
                }
            </div> :

            <div className="flex flex-col flex-1 h-full space-y-[16px] divide-y-[1px] divide-Divider-Default overflow-y-auto no-scrollbar">
                <p>
                    {SEARCHING_PROFESSIONALS_DESCRIPTION}
                </p>

                <div className="my-small">
                    <OpenFavoriteProfessionals/>
                </div>
            </div>
            }

        </div>
    )
}