import React from "react";
import { CancelInternalProfessionalClaimReasons } from "./CancelInternalProfessionalReasons";
import { deleteClaimRequest } from "services/claims";
import { ClaimRequest, DenarioErrorPayload } from "types/claims";
import { ModalContainer } from "components/common/ModalContainer";
import { ConfirmationModal } from "components/common/ConfirmationModal";
import { handleApiError, isDenarioNotSyncedError } from "services/api";

interface CancelInternalProfessionalClaimModalProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: (reason: string) => void;
    shiftId: number;
    claim: ClaimRequest;
    goBack: () => void;
    onDelete: (reason: string) => void;
}

export const CancelInternalProfessionalClaimModal: React.FC<CancelInternalProfessionalClaimModalProps> = ({
    isOpen,
    onClose,
    onConfirm,
    shiftId,
    claim,
    goBack,
    onDelete
}) => {
    const [selectedReason, setSelectedReason] = React.useState<string>("");
    const [retryingActionWithDenario, setRetryingActionWithDenario] = React.useState(false);
    const [actionWithoutDenario, setActionWithoutDenario] = React.useState(false);
    const [denarioError, setDenarioError] = React.useState<DenarioErrorPayload | null>(null);

    const cancelClaimRequest = (skipConstraints?: boolean) => {
        deleteClaimRequest(shiftId, claim.id, selectedReason, skipConstraints)
            .then(() => {
                goBack();
                onDelete(selectedReason);
                setRetryingActionWithDenario(false);
                setActionWithoutDenario(false);
                setDenarioError(null);
            })
            .catch((error) => {
                setRetryingActionWithDenario(false);
                setActionWithoutDenario(false);
                if (isDenarioNotSyncedError(error.response.data)) {
                    const extraData = error.response.data.extraData as DenarioErrorPayload;
                    setDenarioError(extraData);
                } else {
                    handleApiError(error);
                }
            });
    };

    return (
        <ModalContainer isOpen={isOpen} onClose={onClose}>
            <div className="rounded-[16px] shadow-custom w-[410px] bg-white">
                <div className="p-medium">
                    <h3 className="heading-md mb-[12px]">¿Quieres revocar la solicitud a este profesional?</h3>
                    <div 
                        className="body-regular" 
                        style={{ 
                            alignSelf: 'flex-start', 
                            fontFamily: 'Roboto', 
                            fontSize: '13px', 
                            lineHeight: '20px',
                            color: '#7D7D7D', 
                            marginBottom: '12px' 
                        }}
                    >
                        Se cancelará la solicitud y el turno volverá a estar disponible para la configuración original. Este profesional
                        no será capaz de volver a solicitar.
                    </div>
                    <div className="info caption" style={{
                        fontFamily: 'Roboto',
                        fontSize: '13px',
                        fontWeight: 400,
                        lineHeight: '20px',
                        color: '#2C3038',
                    }}>
                        Motivo de la cancelación
                    </div>
                    <CancelInternalProfessionalClaimReasons 
                        onSelectReason={setSelectedReason} 
                        selectedReason={selectedReason} 
                    />                    
                    <div className="flex flex-row items-center mt-[24px]">
                        <button
                            onClick={onClose}
                            className="flex flex-1 text-center text-Action-Primary items-center justify-center py-large px-small"
                        >
                            <p className="action-regular w-full">
                                Volver
                            </p>
                        </button>

                        <button
                            className="flex flex-1 py-large px-small rounded-[100px] text-center bg-Negative-400 text-Text-Inverse"
                            onClick={() => cancelClaimRequest()}
                        >
                            <p className="w-full action-regular">
                                Cancelar solicitud
                            </p>
                        </button>
                    </div>
                </div>
            </div>

            {denarioError && (
                <ConfirmationModal
                    title={denarioError.title}
                    subtitle={denarioError.description}
                    isOpen={true}
                    handleClose={() => {
                        setDenarioError(null);
                        setRetryingActionWithDenario(false);
                        setActionWithoutDenario(false);
                    }}
                    dismissTitle={denarioError.actions.secondary.title}
                    buttonTitle={denarioError.actions.primary.title}
                    onPress={() => {
                        setRetryingActionWithDenario(true);
                        cancelClaimRequest();
                    }}
                    onDismiss={() => {
                        setActionWithoutDenario(true);
                        cancelClaimRequest(true);
                    }}
                    buttonIsLoading={retryingActionWithDenario}
                    dismissIsLoading={actionWithoutDenario}
                />
            )}
        </ModalContainer>
    );
};
