import { DataFieldSubmission, FacilityDataFieldDefinition, InternalProfessional } from "../../types/internal"
import { ProfessionalDataField } from "../../types/claims"
import LivoIcon from "../../assets/LivoLogo"
import { PROFESSIONAL_DATA_TITLE } from "../../locales/professionalClaim"
import { ManageClaimContainer } from "../../components/shiftDetails/professionalClaim/ManageClaimContainer"
import { useState } from "react"
import { FacilityDataField } from "./FacilityDataField"
import { ProfessionalCardHeader } from "components/shiftDetails/professionalClaim/ProfessionalCardHeader"
import { EditProfessionalCardHeader } from "./EditProfessionalCardHeader"
import { Category } from "types/shifts"
import { RootState } from "store/types"
import { useSelector } from "react-redux"
import { getDenarioProfessional } from "services/internal"


interface EditInternalProfessionalAttributesProps {
    internalProfessionalAttributes: ProfessionalDataField[],
    acceptTitle?: string,
    rejectTitle?: string,
    hideIcons?: boolean,
    onAccept: (newAttributes: DataFieldSubmission[], newCategory: Category, newSkills: string[]) => void,
    onReject: () => void,
    dataFieldDefinitions: FacilityDataFieldDefinition[]
    professional: InternalProfessional
}

export const EditInternalProfessionalAttributes: React.FC<EditInternalProfessionalAttributesProps> = ({
    internalProfessionalAttributes,
    acceptTitle,
    rejectTitle,
    hideIcons,
    onAccept,
    onReject,
    dataFieldDefinitions,
    professional
}) => {

    const attributesFromDetails = internalProfessionalAttributes.map((attribute) => ({
        key: attribute.key,
        selectedValues: attribute.values?.length ? attribute.values.map(value => value.value) : [attribute.value],
        editable: attribute.editable
    }))
    const [dataFieldSubmissions, setDataFieldSubmissions] = useState<DataFieldSubmission[]>(attributesFromDetails)
    const [selectedCategory, setSelectedCategory] = useState<Category>(professional.category)
    const validEntry = dataFieldSubmissions.length === dataFieldDefinitions.length && dataFieldSubmissions.every((attribute) => attribute.selectedValues.length > 0)
    const { accountInfo } = useSelector((state: RootState) => state.account)
    const [selectedSkills, setSelectedSkills] = useState<string[]>(professional.skills?.professionalSkills || [])
    const personalDataFieldKeys = ['NATIONAL_ID']
    const [appliedDenarioProfessionalData, setAppliedDenarioProfessionalData] = useState<Boolean>(false)
    
    function getPersonalDataFields() {  // National id data field will be render in the header
        return dataFieldDefinitions.filter((dataField) => personalDataFieldKeys.includes(dataField.key))
    }

    function getExtraDataFields() {
        return dataFieldDefinitions.filter((dataField) => !personalDataFieldKeys.includes(dataField.key))
    }

    return (
        <div
            className="flex flex-col w-full py-medium"
            style={{
                height: '100%', // Added to ensure the container takes up the full height
                position: 'relative' // Added to ensure child absolute positioning works
            }}
        >
            <div
                className="flex flex-1 flex-col w-full p-medium  overflow-y-scroll no-scrollbar"

            >
                <EditProfessionalCardHeader
                    professionalProfile={professional}
                    modality={null}
                    selectedCategory={selectedCategory}
                    availableCategories={accountInfo?.facility.categories || []}
                    setSelectedCategory={setSelectedCategory}
                    selectedSkills={selectedSkills}
                    setSelectedSkills={setSelectedSkills}
                    personalDataFieldDefinitions={getPersonalDataFields()}
                    dataFieldSubmissions={dataFieldSubmissions}
                    setDataFieldSubmissions={setDataFieldSubmissions}
                    appliedDenarioProfessionalData={appliedDenarioProfessionalData}
                    setAppliedDenarioProfessionalData={setAppliedDenarioProfessionalData}
                />

                <div
                    className="flex flex-col w-full"
                >
                    <div
                        className="flex flex-row space-x-small mb-large"
                    >
                        <LivoIcon name='id-badge-2' size={24} color='#ACBBCB' />
                        <p
                            className="body-regular"
                        >
                            {PROFESSIONAL_DATA_TITLE}
                        </p>
                    </div>
                    <div
                        className="flex flex-col space-y-large"
                    >
                        {
                            getExtraDataFields().map((dataField, index) => {
                                const originalAttribute = attributesFromDetails.find((attribute) => attribute.key === dataField.key)
                                const newSubmission = dataFieldSubmissions.find((attribute) => attribute.key === dataField.key)
                                const hasChanged = originalAttribute && originalAttribute.selectedValues.join() !== newSubmission?.selectedValues.join()
                                const editable = (originalAttribute ? originalAttribute.editable : true) && (newSubmission?.editable != false)
                                return (
                                    <FacilityDataField
                                        key={index}
                                        editable={editable}
                                        facilityDataFieldDefinition={dataField}
                                        dataFieldSubmission={dataFieldSubmissions.find((attribute) => attribute.key === dataField.key)}
                                        setDataFieldSubmission={(dataFieldSubmission) => {
                                            const newDataFieldSubmissions = dataFieldSubmissions.filter((attribute) => {
                                                return attribute.key !== dataField.key
                                            })
                                            newDataFieldSubmissions.push(dataFieldSubmission)
                                            setDataFieldSubmissions(newDataFieldSubmissions);
                                        }}
                                        hasChanged={hasChanged}
                                        onChange={(dataFieldSubmission) => {}}
                                        errorMessage={''}
                                    />)
                            }
                            )
                        }
                    </div>
                    <div
                        className="h-[200px]" // add empty space to ensure the content doesn't get hidden by the floating container
                    ></div>
                </div>
                <div
                    className="min-w-fit"
                    style={{
                        position: 'absolute', // Makes this container float
                        bottom: 10,
                        left: 0,
                        right: 0,
                        padding: '16px', // Optional: add some padding
                        background: 'white', // Optional: add a background color for contrast
                        zIndex: 10 // Ensures it's above other content but still below modal
                    }}
                >
                    <ManageClaimContainer
                        onAccept={() => onAccept(dataFieldSubmissions, selectedCategory, selectedSkills)}
                        onReject={onReject}
                        hideIcons={hideIcons}
                        acceptTitle={acceptTitle}
                        rejectTitle={rejectTitle}
                        disableAccept={!validEntry}
                    />
                </div>
            </div>

        </div>
    )
}