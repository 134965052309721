import { ClaimRequest, DenarioErrorPayload } from "../../../types/claims"
import LivoIcon from "../../../assets/LivoLogo";
import { DropDownWithInput } from "../../../components/publishShift/DropDownWithInput";
import { MultipleLineInput } from "../../../components/common/MultipleLineInput";
import { ActionButton } from "../../../components/common/ActionButton";
import { shiftClaimAccept } from "../../../services/claims";
import { SLOT_REASON_ACCEPT, SLOT_REASON_COMMENT, SLOT_REASON_LABEL } from "../../../locales/shiftClaimDetails/es";
import { ReactNode, useState } from "react";
import { ProfilePicture } from "../ProfilePicture";
import { handleApiError, isDenarioNotSyncedError } from "services/api";
import { ConfirmationModal } from "components/common/ConfirmationModal";

interface AcceptProfessionalWithClaimReasonProps {
    claim: ClaimRequest;
    goBack: () => void;
    onAccept: () => void;
    shiftId: number;
}

export const AcceptProfessionalWithClaimReason: React.FC<AcceptProfessionalWithClaimReasonProps> = ({
    claim,
    goBack,
    onAccept,
    shiftId
}) => {
    const [newComment, setNewComment] = useState('')
    const [newSlotReason, setNewSlotReason] = useState('')
    const [loading, setLoading] = useState(false)

    const [retryingActionWithDenario, setRetryingActionWithDenario] = useState(false);
    const [actionWithoutDenario, setActionWithoutDenario] = useState(false);
    const [denarioError, setDenarioError] = useState<DenarioErrorPayload | null>(null);

    const acceptRequestCall = (skipConstraints?: boolean) => {
        setLoading(true);
        shiftClaimAccept(shiftId, claim.id, newSlotReason, newComment, skipConstraints).then(() => {
            onAccept();
            setRetryingActionWithDenario(false);
            setActionWithoutDenario(false);
            setDenarioError(null);
        }).catch((error) => {
            setRetryingActionWithDenario(false);
            setActionWithoutDenario(false);
            if (isDenarioNotSyncedError(error.response.data)) {
                const extraData = error.response.data.extraData as DenarioErrorPayload;
                setDenarioError(extraData);
            } else {
                handleApiError(error);
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <div
            className="flex flex-col w-full"
        >
            <div
                className="flex flex-row w-full mb-small space-x-tiny items-center"
            >
                <button
                    onClick={goBack}
                >
                    <LivoIcon name='close' size={24} color='#707A91' />

                </button>
                <p
                    className="heading-md"
                >
                    {SLOT_REASON_LABEL}
                </p>
            </div>
            <div
                className="flex flex-col w-full p-medium self-start space-y-large"
            >
                <div
                    className="flex flex-col items-center w-full justify-center space-y-small"
                >
                    <ProfilePicture
                        profilePictureUrl={claim.professionalProfile.profilePictureUrl}
                        size={64}
                        modality={claim.modality}
                    />
                    <p
                        className="heading-md"
                    >
                        {claim.professionalProfile.firstName} {claim.professionalProfile.lastName}
                    </p>
                </div>

                <DropDownWithInput
                    options={claim.slotReasonOptions?.map((reason) => {
                        return {
                            id: reason.value,
                            name: reason.displayText
                        }
                    })}
                    selectedOptionId={newSlotReason}
                    setOptionId={(value) => setNewSlotReason(value)}
                    placeHolder={SLOT_REASON_LABEL}
                />
                <div>
                {claim.slotReasonCommentDisplayed && (    
                    <MultipleLineInput
                        setInputValue={setNewComment}
                        inputValue={newComment}
                        placeHolder={SLOT_REASON_COMMENT}
                    />
                )}
                </div>

                <ActionButton
                    onClick={() => {
                        acceptRequestCall()
                    }}
                    isDisabled={newSlotReason.length === 0}
                    isLoading={loading}
                    style={{
                        flex: 1,
                    }}
                >
                    <p
                        className="w-full action-regular py-tiny"
                    >
                        {SLOT_REASON_ACCEPT}
                    </p>
                </ActionButton>
            </div>

            {denarioError && (
                <ConfirmationModal
                    title={denarioError.title}
                    subtitle={denarioError.description}
                    isOpen={true}
                    handleClose={() => {
                        setDenarioError(null);
                        setRetryingActionWithDenario(false);
                        setActionWithoutDenario(false);
                    }}
                    dismissTitle={denarioError.actions.secondary.title}
                    buttonTitle={denarioError.actions.primary.title}
                    onPress={() => {
                        setRetryingActionWithDenario(true);
                        acceptRequestCall();
                    }}
                    onDismiss={() => {
                        setActionWithoutDenario(true);
                        acceptRequestCall(true);
                    }}
                    buttonIsLoading={retryingActionWithDenario}
                    dismissIsLoading={actionWithoutDenario}
                />
            )}
        </div>
    )
}